exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_PURE_IMPORT_0___ = require("./logo.svg");
var ___CSS_LOADER_URL_IMPORT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_PURE_IMPORT_0___);
// Module
exports.push([module.id, "._2ZNEjZPw_zAHE3dmr2S3SZ{background:#333}._3bntFupXHrMRhBA0jY1nzK{background:url(" + ___CSS_LOADER_URL_IMPORT_0___ + ") no-repeat center center;height:30px;margin:10px 0}._3WoO3uw76NaLHUfyT-TcF2{color:#fff;left:20px;font-size:13px;position:absolute;top:18px;text-decoration:none;text-transform:uppercase}\n", ""]);
// Exports
exports.locals = {
	"header": "_2ZNEjZPw_zAHE3dmr2S3SZ",
	"logo": "_3bntFupXHrMRhBA0jY1nzK",
	"backLink": "_3WoO3uw76NaLHUfyT-TcF2"
};