import React from "react";
import { Switch, Route } from "react-router";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons/faArrowCircleLeft";

import DetailPage from "../DetailPage";
import MainPage from "../MainPage";

import style from "./style.scss";

const App = () => (
  <>
    <div className={style.header}>
      <div className={style.logo} />
      <Route path="/:code">
        <Link to="/" className={style.backLink}>
          <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
        </Link>
      </Route>
    </div>
    <Switch>
      <Route path="/:code" component={DetailPage} />
      <Route path="/" component={MainPage} />
    </Switch>
  </>
);

export default App;
