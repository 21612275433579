import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import style from "./style.scss";

const MainPage = () => {

  const history = useHistory();
  const [code, setCode] = useState("");
  let backSpaceTrigger = false;

  const onInputKeyDown = (e:any) => {
    backSpaceTrigger = e.keyCode == 8 ? true : false
  }

  const onContainerLoad = () => {
    let mixpanel = (window as any).mixpanel 
    let d = new Date();
    let currentDate = d.toISOString()
    mixpanel.track( "Main Page Session", {
      "time": currentDate 
    }); 
  }

  const onInputChange = (e:any) => {
    if (e.target.value.length === 6){
      if (e.target.value.indexOf(' ') === -1) {
        e.target.value = e.target.value.slice(0,3) + ' '
        + e.target.value.slice(3,6);
        setCode(e.target.value);
      }
    }
    if (backSpaceTrigger && e.target.value.length === 3) {
      e.target.value = e.target.value + ' ';
    }
    if (backSpaceTrigger === false && e.target.value.length === 3) {
      e.target.value = e.target.value + ' '
    } else if (backSpaceTrigger === true && e.target.value.length === 5) {
      e.target.value = e.target.value.slice(0,3)
    }
    setCode(e.target.value);
  }

  const onFormSubmit = () => {
    let codeSubmit = code.slice(0,3) + code.slice(4,7);
    history.push("/" + codeSubmit + `?time=${Date.now()}`);

  }

  return (
    <div className={style.container} onLoad={onContainerLoad}>
      <form className={style.contentContainer} onSubmit={onFormSubmit}>
        <h1>Enter code from your wearable device:</h1>
        <div className={style.inputForm}>
          <input
            data-hj-whitelist
            type="text"
            maxLength={7}
            value={code}
            onKeyDown={onInputKeyDown}
            onChange={onInputChange}
          />
          <img src="https://wearkineticstatic.s3.amazonaws.com/portal-assets/portal-key.svg"/>
        </div>
        <input type="submit" value="Enter" />
      </form>
    </div>
  );
};

export default MainPage;