import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

import App from './components/App';

render(App);

if (module.hot !== undefined) {
  module.hot.accept('./components/App', () => {
    render(require('./components/App').default);
  });
}

/** Renders the app into the root element. */
function render(Component: React.ReactType) {
  ReactDOM.render(
    <HashRouter>
      {React.createElement(Component)}
    </HashRouter>,
    document.getElementById('root')!,
  );
}
