import React, { Component, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import css from './styles.scss';


interface Params { code: string; ticket: string; }

const DetailPage = () => { 
  
  const match = useRouteMatch<Params>();
  const { code } = match.params;
  let dataImageURL = `https://wearkineticstatic.s3.amazonaws.com/coach-pdfs/${code}.png?${Date.now()}`;
  let errorImage = `https://wearkineticstatic.s3.amazonaws.com/portal_error_invalid_code.png`;
  const [imageURL, setImageURL] = useState(dataImageURL);
  const [errorFire, setErrorFire] = useState(0);

  const fireMixpanelEvent = (eventName: string) => {
    let mixpanel = (window as any).mixpanel 
    mixpanel.people.set({ "ID": code });
    mixpanel.alias(code);
    mixpanel.identify(code);
    mixpanel.track( eventName, {"id": code } );  
    let d = new Date();
    let currentDate = d.toISOString()
    mixpanel.track( "View Page Session", {
      "time": currentDate,
      "id": code 
    });     
  }

  const handleImageLoaded = () => {
    if (errorFire < 1) {
      fireMixpanelEvent("Image Loaded");
      ;
    }
  }

  const handleImageErrored = () => {
    setErrorFire(1);
    fireMixpanelEvent("Image Error");
  }

  return ( 
    <img 
      className={css.userData} 
      src={imageURL}
      onLoad={ ()=> { setImageURL(imageURL); handleImageLoaded() } }
      onError={ ()=> { setImageURL(errorImage); handleImageErrored() } }
      /> 
  );
}

export default DetailPage;
